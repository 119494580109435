import React from 'react';

const MONTH_NAMES = {
  1: 'Január',
  2: 'Február',
  3: 'Marec',
  4: 'Apríl',
  5: 'Máj',
  6: 'Jún',
  7: 'Júl',
  8: 'August',
  9: 'September',
  10: 'Október',
  11: 'November',
  12: 'December',
};

const MonthName = ({ date }) => {
  // Predpokladáme, že dátum je vo formáte 'YYYY-MM-DD'
  const month = new Date(date).getMonth() + 1; // JavaScript mesiace začínajú od 0
  const monthName = MONTH_NAMES[month];

  return (
    <>{monthName}</>
  );
};

export default MonthName;