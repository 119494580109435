import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './App.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TaskForm from './components/TaskForm';
import TaskList from './components/TaskList';
import { format } from "date-fns";
import { sk } from "date-fns/locale";
import MonthName from './components/MonthName';

Chart.register(...registerables);

function App() {

  const costs = 8000;
  const [tasks, setTasks] = useState([]);
  const monthlyIncome = [
       {
          clientName: 'Autostyl',
          taskName: 'Mesačné práce',
          hours: 20,
          hourlyRate: 40,
      },
      {
          clientName: 'Yeme',
          taskName: 'Mesačné práce',
          hours: 1,
          hourlyRate: 1400,
      },
      {
          clientName: 'LittlePeople',
          taskName: 'Server',
          hours: 1,
          hourlyRate: 215,
      },
      {
          clientName: 'Danill',
          taskName: 'Server',
          hours: 1,
          hourlyRate: 88,
      },
      {
          clientName: 'DobraMiska',
          taskName: 'Mesacne prace, hotline',
          hours: 1,
          hourlyRate: 1280,
      },
      {
          clientName: 'KJG',
          taskName: 'server - Mesacne prace, hotline',
          hours: 1,
          hourlyRate: 750,
      }
  ];

  const [tasksTotal, setTasksTotal] = useState(0);
  const [chartData, setChartData] = useState({
    labels: ['Náklady', 'Spolu príjmy', 'Paušály', 'Objednávky'],
     options: {
        scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    },
    datasets: [
      {
        label: 'Hodnoty',
        backgroundColor: ['#f77189', '#97a431', '#36ada4', '#a48cf4'],
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        data: [costs, 0],
      },
    ],
  });

  const monthName = format(new Date(), 'MMMM', { locale: sk });

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {

    setChartData((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: [costs, dynamicValue + 4533, 4533, dynamicValue],
        },
      ],
    }));

    let dynamicValue = 0;
    tasks.forEach((task) => {
      dynamicValue += task.hours * task.hourlyRate;
    });

    setTasksTotal(dynamicValue);

  }, [tasks]);

  const fetchTasks = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tasks`)
      .then((response) => {
        setTasks(response.data);
      })
      .catch((error) => {
        console.error('Chyba pri nactiani zaznamu: ' + error.message);
      });
  };

  return (
    <div className='bg-gradient-to-r from-cyan-500 to-blue-500'>
      <div className="container mx-auto p-4">
        <div className='flex justify-center mb-4'>
          <a href='/'>
             <img src='/logo.svg'></img>
          </a>
        </div>
        <div className="flex space-x-4 mb-4 bg-white p-6 rounded">
          <TaskForm fetchTasks={fetchTasks} />
        </div>
        <div className='grid md:grid-cols-1 gap-4'>
          <TaskList tasks={tasks} fetchTasks={fetchTasks} tasksTotal={tasksTotal} monthlyIncome={monthlyIncome} />
          <div className='bg-gray-50 rounded p-6'>
            <div className='grid grid-cols-1 gap-8'>
              <div>
                <h3 className='text-center text-lg font-bold pb-4'>Výsledok hospodárenia - <MonthName date={new Date()} /></h3>
                <Bar data={chartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position='bottom-right' />
    </div>
  );
}

export default App;
