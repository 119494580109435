import React, { useEffect, useState } from 'react';
import { FaTrash, FaShare, FaArchive } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip'

function TaskList({ tasks, fetchTasks, tasksTotal, monthlyIncome }) {

    const handleDeleteTask = (taskId) => {
        confirmAlert({
        title: 'Naozaj chceš vymazať tento task?',
        buttons: [
            {
            label: 'Ano',
            onClick: () => {
                axios
                .delete(`${process.env.REACT_APP_BACKEND_URL}/tasks/${taskId}`)
                .then(() => {
                    toast.success('Úloha odstánená');
                    fetchTasks();
                })
                .catch((error) => {
                    console.error('Chyba pri odstranovani: ' + error.message);
                    toast.error('Chyba pri odstranovani')
                });
                },
            }, {
            label: 'Nie',
            onClick: () => { },
            },
        ],
        });
    };

    const handleStatusChange = (taskId, newStatus) => {
        axios
          .put(`${process.env.REACT_APP_BACKEND_URL}/tasks/${taskId}`, {
            status: newStatus,
          })
          .then(() => {
             toast.success('Úloha aktualizovaná');
             fetchTasks();
          })
          .catch((error) => {
            console.error('Chyba při aktualizaci stavu úkolu: ' + error.message);
          });
      };

    const handleArchiveTask = (taskId) => {
        confirmAlert({
        title: 'Naozaj chceš archivovať tento task?',
        buttons: [
            {
            label: 'Ano',
            onClick: () => {
                  axios
                  .put(`${process.env.REACT_APP_BACKEND_URL}/tasks/${taskId}`, {
                    archive: 1,
                    status: 3,
                  })
                  .then(() => {
                     toast.success('Úloha archivovaná');
                     fetchTasks();
                  })
                  .catch((error) => {
                    console.error('Chyba pri aktualizácii: ' + error.message);
                  });
                },
            }, {
            label: 'Nie',
            onClick: () => { },
            },
        ],
        });
    };

    const sendToSlack = (task) => {
        const slackToken = 'xoxp-14211552502-21416195315-5970341005236-ab9c1510df1216ff120435e37b3abcec';
        const slackChannel = 'billionairesclub';
        const message = `Fakturovať! : Klient: ${task.clientName}, Task: ${task.taskName}, Hodiny: ${task.hours}, Hodinová sazba: ${task.hourlyRate}`;

        axios
        .post(
            'https://slack.com/api/chat.postMessage',
            {
            channel: slackChannel,
            text: message,
            },
            {
            headers: {
                Authorization: `Bearer ${slackToken}`,
                'Content-Type': 'application/json',
            },
            }
        )
        .then((response) => {
            if (response.data.ok) {
                toast.success('Správa odoslaná');
            } else {
                console.log(response.data);
                toast.error('Chyba pri odosielaní správy');
            }

        })
        .catch((error) => {
            toast.error('Chyba pri odosielani');
        });
    }

  return (
    <div className='rounded bg-white p-6'>
      <h3 className='font-bold text-lg my-5'>Objednávky nad rámec 💸</h3>
      <table className="table-auto w-full">
        <thead className='text-xs text-gray-700 uppercase text-left'>
          <tr className='align-bottom'>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Klient</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Úloha</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Stav</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Hodín</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Hodinová <br /> sazba</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Celkom</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">&nbsp;</th>
          </tr>
        </thead>
        <tbody className='text-xs text-gray-700 uppercase'>
          {tasks.map((task, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
              <td className="px-4 border-b-1 py-2">{task.clientName}</td>
              <td className="px-4 border-b-1 py-2 normal-case">{task.taskName}</td>
              <td className="px-4 border-b-1 py-2">
                <select
                className='block p-2 pr-3 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
                  value={task.status}
                    onChange={(e) => handleStatusChange(task.id, e.target.value)}
                >
                    <option value='1'>Nový</option>
                    <option value='2'>Prebieha</option>
                    <option value='3'>Hotovo</option>
                </select>
              </td>
              <td className="px-4 border-b-1 py-2">{task.hours}</td>
              <td className="px-4 border-b-1 py-2">{task.hourlyRate}€</td>
              <td className="px-4 border-b-1 py-2">{task.hours*task.hourlyRate}€</td>
              <td className="px-4 border-b-1 py-2">
                <div className='flex gap-4 justify-center'>
                    <button
                      onClick={() => handleDeleteTask(task.id)}
                      className="text-red-500 hover:text-red-700 tooltip-el"
                      data-tooltip-content="Vymazať"
                    >
                      <FaTrash />
                    </button>
                    <button
                        className="text-yellow-500 hover:text-yellow-700 tooltip-el"
                        onClick={() => sendToSlack(task)}
                        data-tooltip-content="Odoslať na Slack"
                    >
                      <FaShare />
                    </button>
                    <button
                        className="text-grey-500 hover:text-grey-700 tooltip-el"
                        onClick={() => handleArchiveTask(task.id)}
                        data-tooltip-content="Archivovať"
                    >
                      <FaArchive />
                    </button>
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={7}>
                <hr />
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2"></td>
            <td className="px-4 py-2 text-md font-bold text-right">Celkom</td>
            <td className={`px-4 py-2 font-bold text-lg ${tasksTotal < 2500 ? 'text-red-700' : tasksTotal <= 3733 ? 'text-orange-700' : 'text-green-700'}`}>{tasksTotal}€</td>
          </tr>
        </tbody>
      </table>
      <h3 className='font-bold text-lg my-5'>Paušálne príjmy 💰</h3>
      <table className="table-auto w-full">
        <thead className='text-xs text-gray-700 uppercase text-left'>
          <tr className='align-bottom'>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Klient</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Úloha</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Celkom</th>
          </tr>
        </thead>
        <tbody className='text-xs text-gray-700 uppercase'>
            {monthlyIncome.map((task, index) => (
                <tr  key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                    <td className="px-4 py-4">{task.clientName}</td>
                    <td className="px-4 py-4 capitalize">{task.taskName}</td>
                    <td className="px-4 py-4">{task.hours*task.hourlyRate}€</td>
                </tr>
            ))}
            <tr>
                <td colSpan={6}>
                    <hr />
                </td>
              </tr>
              <tr>
                <td colSpan={2} class="px-4 py-2 text-md font-bold text-right">Celkom</td>
                <td className="px-4 py-2 font-bold text-lg"><span className='text-green-700'>4533€</span></td>
              </tr>
        </tbody>
      </table>
        <Tooltip anchorSelect=".tooltip-el" place="top"></Tooltip>
    </div>
  );
}

export default TaskList;