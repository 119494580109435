import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function TaskForm({ fetchTasks }) {
  const [newTask, setNewTask] = useState({
    clientName: '',
    taskName: '',
    hours: '',
    hourlyRate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const handleAddTask = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/tasks`, newTask)
      .then(() => {
        toast.success('Úloha bola úspešne pridaná');
        fetchTasks();
        setNewTask({
          clientName: '',
          taskName: '',
          hours: '',
          hourlyRate: '',
        });
      })
      .catch((error) => {
        console.error('Chyba pri vkladani záznamu: ' + error.message);
      });
  };

  return (
    <>
      <input
        type="text"
        name="clientName"
        value={newTask.clientName}
        onChange={handleInputChange}
        placeholder="Názov klienta"
        className="border rounded p-2"
      />
      <input
        type="text"
        name="taskName"
        value={newTask.taskName}
        onChange={handleInputChange}
        placeholder="Úloha"
        className="border rounded p-2 w-1/2"
      />
      <input
        type="number"
        name="hours"
        value={newTask.hours}
        onChange={handleInputChange}
        placeholder="Počet hodin"
        className="border rounded p-2"
      />
      <input
        type="number"
        name="hourlyRate"
        value={newTask.hourlyRate}
        onChange={handleInputChange}
        placeholder="Hodinová sazba"
        className="border rounded p-2"
      />
      <button
        onClick={handleAddTask}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
      >
        Pridať
      </button>
    </>
  );
}

export default TaskForm;